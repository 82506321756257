/* performance? ~7% faster? is it worth it?
svg:not(:root), input, textarea, select, symbol, image, marker, pattern, foreignObject {
    overflow:clip;
}
*/
abbr[title] {
    text-decoration-thickness: .13em; /* ok? */
}
/*
chrome uses "Consolas"
"Courier New" is available everywhere? https://www.granneman.com/webdev/coding/css/fonts-and-formatting/web-browser-font-defaults
Can not reproduce need of bug fix "monospace, monospace"
needed? font-size: 1em; /* Correct the odd `em` font sizing in all browsers.
*/
pre, code, kbd, samp {
    font-family: "Courier New", monospace;
}

/*
if attr supported something like this

input:not([type=radio], [type=checkbox], [type=date], [type=color]...) {
    width: calc(attr(size, 20) * .6em + 1em);
}
*/

/*
chrome adds a border-radius, but if reseted, input styles are resetted to old school inputs *

input:not([type=radio], [type=checkbox]) {
    border-radius:0;
    border:1px solid #000;
}
*/